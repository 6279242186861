/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim
* npm run build
=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin used to create google maps
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  Polyline,
} from "react-google-maps";
import classnames from "classnames";
import { Autocomplete } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
//import Moment from 'moment';
// reactstrap components
import { Card,
  Alert,
  Modal,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Badge,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Progress,
  Table,
  Form,
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Pagination, 
  PaginationItem, 
  PaginationLink ,
  FormFeedback,

  Button,} from "reactstrap";
  import ReactToPrint from 'react-to-print';
  import { Link } from 'react-router-dom';
// core components
import ReactDatetime from "react-datetime";
import Header from "components/Headers/Header.jsx";
import Footer from "components/Footers/AdminFooter.jsx";
import { textChangeRangeIsUnchanged } from "typescript";
import axios ,{post} from 'axios'; 
import Moment from 'moment';
// mapTypeId={google.maps.MapTypeId.ROADMAP}
// const MapWrapper = withScriptjs(
//   withGoogleMap(props => (
//     <GoogleMap
//       defaultZoom={12}
//       defaultCenter={{ lat: 40.748817, lng: -73.985428 }}
//       defaultOptions={{
//         scrollwheel: false,
//         styles: [
//           {
//             featureType: "administrative",
//             elementType: "labels.text.fill",
//             stylers: [{ color: "#444444" }]
//           },
//           {
//             featureType: "landscape",
//             elementType: "all",
//             stylers: [{ color: "#f2f2f2" }]
//           },
//           {
//             featureType: "poi",
//             elementType: "all",
//             stylers: [{ visibility: "off" }]
//           },
//           {
//             featureType: "road",
//             elementType: "all",
//             stylers: [{ saturation: -100 }, { lightness: 45 }]
//           },
//           {
//             featureType: "road.highway",
//             elementType: "all",
//             stylers: [{ visibility: "simplified" }]
//           },
//           {
//             featureType: "road.arterial",
//             elementType: "labels.icon",
//             stylers: [{ visibility: "off" }]
//           },
//           {
//             featureType: "transit",
//             elementType: "all",
//             stylers: [{ visibility: "off" }]
//           },
//           {
//             featureType: "water",
//             elementType: "all",
//             stylers: [{ color: "#5e72e4" }, { visibility: "on" }]
//           }
//         ]
//       }}
//     >
//       <Marker position={{ lat: 40.748817, lng: -73.985428 }} />
//     </GoogleMap>
//   ))
// );
const MapWrapper = () => {
  const mapRef = React.useRef(null);
  const linkurl='https://crm.mewahtrans.com.my/api/alfredtest.php?'
  React.useEffect(() => {
    let google = window.google;
    var lat =0;
    var lng =0;
    var urlencoded = new URLSearchParams();
    var status=1;
    urlencoded.append("status", status);
    let map = mapRef.current;
    var link = linkurl + 'mode=readcompany'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
       // 'Access-Control-Allow-Origin': '*',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      if(json.success == 1){
        localStorage.setItem('lat',json.user[0].lat)
        localStorage.setItem('lng',json.user[0].lng)
      }
    })
    const myLatlng = new google.maps.LatLng(localStorage.getItem('lat'), localStorage.getItem('lng'));
    const mapOptions = {
      zoom: 10.5,
      center: myLatlng,
      scrollwheel: false,
      zoomControl: true,
      styles: [
        {
          featureType: "administrative",
          elementType: "labels.text.fill",
          stylers: [{ color: "#444444" }],
        },
        {
          featureType: "landscape",
          elementType: "all",
          stylers: [{ color: "#f2f2f2" }],
        },
        {
          featureType: "poi",
          elementType: "all",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "road",
          elementType: "all",
          stylers: [{ saturation: -100 }, { lightness: 45 }],
        },
        {
          featureType: "road.highway",
          elementType: "all",
          stylers: [{ visibility: "simplified" }],
        },
        {
          featureType: "road.arterial",
          elementType: "labels.icon",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "transit",
          elementType: "all",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "water",
          elementType: "all",
          stylers: [{ color: "#5e72e4" }, { visibility: "on" }],
        },
      ],
    };

    map = new google.maps.Map(map, mapOptions);
    var link = linkurl + 'mode=readhistory'
    var urlencoded = new URLSearchParams();
    urlencoded.append("id", sessionStorage.getItem("ID"));
    urlencoded.append("FromTS", sessionStorage.getItem("startDate"));
    urlencoded.append("ToTS", sessionStorage.getItem("endDate"));
    urlencoded.append("key", "AIzaSyAYnJmyB3UZCqckJYyyxnbVizM2wi91WDk")
    var pathcoor=[]
    var newlatlng
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
       // 'Access-Control-Allow-Origin': '*',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      if(json.success == 1){
        for (var i=0; i<json.user.length;i++){
          if(json.user[i].lat!=0&&json.user[i].lng!=0){
            newlatlng=new google.maps.LatLng(json.user[i].lat,json.user[i].lng)
            pathcoor.push(newlatlng)
        }
      }    
    }
    const Polyline = new google.maps.Polyline({
      path:pathcoor,
      strokeColor: '#000000',
      geodesic:true,
      strokeWeight: 4,
      strokeOpacity: 0.9,
      visible:true,
      map: map,
        });
    Polyline.setMap(map)
  })
        
      
    

  }, []);
  return (
    <>
      <div
        style={{ height: `600px` }}
        className="map-canvas"
        id="map-canvas"
        ref={mapRef}
      ></div>
    </>
  );
};
class Geofence extends React.Component {

  constructor(props){
    super(props);
 
  }



  state = {
    tabs: 2,
    tabsimportexport:1,
    detailpopout:false,
    detailorderpopout:false,
    detailprocesspopout:false,
    rotpendinglist:[],
    detailpendinglist:[],
    detailorderlist:[],
    completelist:[],
    Billtoparty:sessionStorage.getItem('Billtoparty'),
    Createdbyaccount:sessionStorage.getItem('Ref'),
    loadingmodal:false,
    loadingmodalorder:false,
    loadingmodalprocess:false,
    loading:false,
    shipper:'',
    bookingno:'',
    pol:'',
    pod:'',
    shipperorder:'',
    bookingnoorder:'',
    polorder:'',
    podorder:'',
    rotnumbermodalpending:'',
    rotnumbermodalordertab:'',
    consignmentnoprocesstab:'',
    statusprocesstab:'',
    containernoprocesstab:'',
    requiredateprocesstab:'',
    closingdateprocesstab:'',
    typeprocesstab:'',
    color1:'dark',
    color2:'dark',
    color3:'dark',
    color4:'dark',
    color5:'dark',
    color6:'dark',
    color1text:'whitesmoke',
    color2text:'whitesmoke',
    color3text:'whitesmoke',
    color4text:'whitesmoke',
    color5text:'whitesmoke',
    color6text:'whitesmoke',
    buttoncolor1:'white',
    buttoncolor2:'primary',
    buttoncolor3:'white',
    userlist:[],
    currentcompletedpagenumber:1,
    searchtextvalue:'',
    printexport:'',
    printbilltoparty:'',
    printbookingno:'',
    printclosing:'',
    printcommodity:'',
    printcontainer1:'',
    printcontainer2:'',
    printcontainer3:'',
    printcontainer4:'',
    printcontainer5:'',
    printcontainer6:'',
    printcontainer7:'',
    printcontainer8:'',
    printeta:'',
    printforwardingagent:'',
    printpic:'',
    printpickup:'',
    printpod:'',
    printpol:'',
    printremark:'',
    printrequiredate:'',
    printrequiretime:'',
    printshipper:'',
    printshippingaddresss:'',
    printshippingliner:'',
    printsize1:'',
    printsize2:'',
    printsize3:'',
    printsize4:'',
    printtype1:'',
    printtype2:'',
    printtype3:'',
    printtype4:'',
    printvolume1:'',
    printvolume2:'',
    printvolume3:'',
    printvolume4:'',
    printvoyage:'',
    editpendingrotpopout:false,
    editorderrotnumber:'',
    isopendropdownshipper:false,
    isopendropdownshipmenttype:false,
    isopendropdownpod:false,
    isopendropdownpol:false,
    shipmenttypeadropdowntext:'',
    isopendropdownoperator:false,
    operatoreadropdowntext:'',
    isopendropdownpickup:false,
    isopendropdownreturnyard:false,
    pickupdropdowntext:'',
    returnyarddropdowntext:'',
    type2dropdowntext:'',
    isopendropdownforwardingagent:false,
    forwardingagentdropdowntext:'',
    quotationtabledatafinal:[],
    operatorlistdata:[],
    yardlist:[],
    typelist:[],
    typelistfirstone:[],
    Billtoparty:sessionStorage.getItem('Billtoparty'),
    deliveryaddress:'',
    areatextbox:'',
    poltext:'',
    podtext:'',
    disableddropdownpol:true,
    disableddropdownpod:true,
    volumenumberinput:0,
    volumenumberinput1:0,
    volumenumberinput2:0,
    volumenumberinput3:0,
    volumenumberinput4:0,
    volumenumberinput5:0,
    volumenumberinput6:0,
    volumenumberinput7:0,
    volumenumberinput8:0,
    volumenumberinput9:0,
    type1dropdowntext:'',
    type1dropdowntext1:'',
    type1dropdowntext2:'',
    type1dropdowntext3:'',
    type1dropdowntext4:'',
    type1dropdowntext5:'',
    type1dropdowntext6:'',
    type1dropdowntext7:'',
    type1dropdowntext8:'',
    type1dropdowntext9:'',
    isopendropdowntype:false,
    isopendropdowntype1:false,
    isopendropdowntype2:false,
    isopendropdowntype3:false,
    isopendropdowntype4:false,
    isopendropdowntype5:false,
    isopendropdowntype6:false,
    isopendropdowntype7:false,
    isopendropdowntype8:false,
    isopendropdowntype9:false,
    volumeshowrow:0,
    rowvolume1:false,
    rowvolume2:false,
    rowvolume3:false,
    rowvolume4:false,
    rowvolume5:false,
    rowvolume6:false,
    rowvolume7:false,
    rowvolume8:false,
    minusbuttonshow:false,
    forwardingagentlist:[],
    aftersubmitstatus:0,
    picvalue:'',
    contactvalue:'',
    bookingno:'',
    Vsleta:'',
    closingday:'',
    emptytest:[],
    submitbuttonclick:0,
    picvalidation:false,
    contactvalidation:false,
    podtextvalidation:false,
    poltextvalidation:false,
    bookingnovalidation:false,
    vsletavalidation:false,
    closingvalidation:false,
    volumenumber1validation:false,
    volumenumber2validation:false,
    volumenumber3validation:false,
    volumenumber4validation:false,
    volumenumber5validation:false,
    volumenumber6validation:false,
    volumenumber7validation:false,
    volumenumber8validation:false,
    volumenumber9validation:false,
    volumenumber10validation:false,
    containervalidation:false,
    requestdatevalidation:false,
    vesselnamevalidation:false,
    vesselvoyagevalidation:false,
    commodityvalidation:false,
    areadropdowntext:'',
    commodity:'',
    vesselname:'',
    vesselvoyage:'',
    errorsubmit:false,
    samedate:false,
    alerterrormessage:'',
    attachmentquantity:1,
    selectedFile1: null,
    selectedFile2: null,
    selectedFile3: null,
    selectedFile4: null,
    selectedFile5: null,
    selectedFile6: null,
    selectedFile7: null,
    selectedFile8: null,
    selectedFile9: null,
    selectedFile10: null,
    selectedFileexistname1: null,
    selectedFileexistname2: null,
    selectedFileexistname3: null,
    selectedFileexistname4: null,
    selectedFileexistname5: null,
    selectedFileexistname6: null,
    selectedFileexistname7: null,
    selectedFileexistname8: null,
    selectedFileexistname9: null,
    selectedFileexistname10: null,
    container0:'',
    container1:'',
    container2:'',
    container3:'',
    container4:'',
    container5:'',
    container6:'',
    container7:'',
    requestdate0:'',
    vsltoshow:'',
    closingdateshow:'',
    numberrowaftersubmit:0,
    cancelattachmentbutton1:false,
    cancelattachmentbutton2:false,
    cancelattachmentbutton3:false,
    cancelattachmentbutton4:false,
    cancelattachmentbutton5:false,
    cancelattachmentbutton6:false,
    cancelattachmentbutton7:false,
    cancelattachmentbutton8:false,
    cancelattachmentbutton9:false,
    cancelattachmentbutton10:false,
    requestdateerrorinvalid:false,
    popoutemail:false,
    linkurl:"https://gpstracking.mewahtrans.com.my/api/alfredtest.php?",
    //imageurllink:"http://crm.mewahtrans.com.my/img/profile_img/",
   // linkurl:"https://cors-anywhere.herokuapp.com/http://crm.mewahtrans.com.my/api/mewahapicode.php?",
    imageurllink:"https://gpstracking.mewahtrans.com.my/api/img/profile_img/",
  };

  
  render() {
    return (
      <>
        <Header />
        {/* Page contentas */}
        <Container className=" mt--10" fluid>
          {/* Table */}
          <Row>
            <div className=" col-12 ">          
        
        <Col md="12  mt-3">
        {window.innerWidth <= 760 ?
                <div className="d-md-inline-block">
                <NavLink className="nav-link-icon d-md-inline-block col-12" >
            
              </NavLink>
               
              
                </div>
         :
<div>
      <NavLink className="nav-link-icon" >
    
       </NavLink>
       
        
       </div>
        }

                
        </Col>
            </div>
           
           
            <div className=" col-12 ">
              <Card className=" shadow  ">
              
              <Row>
                <Col >

                <div className=" col-12 d-md-inline-block ">
                <div className="nav-wrapper ">
              
                <Button
                      color="primary"
                      data-dismiss="modal"
                      type="button"
                      className="alignrightbutton"
                      // onClick={(e) => this.setState({detailpopout:true})}
                    >
                      +Add New Geofence
                    </Button> 
            
            
            </div>


                </div>
                
               
                </Col>

                <Col md="8 form-inline  d-md-flex">
             
              
                 {/* Searchbar is here */}
                   {/* till here */}
                   {window.innerWidth <= 760 ?
                <InputGroup className="col-11 mr-3  ml-lg-auto alignmiddletbutton input-group-alternative">
                <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                   
                      <i className="fas fa-search" />
                     
                    </InputGroupText>
                  </InputGroupAddon>
                
                    <Input
                      placeholder="Search" 
                      value={this.state.searchtextvalue}
                      onChange = {(event) => this.setState({ searchtextvalue: event.target.value })}
                      type="text"
                      onKeyPress={(e) => {this.handleKeyPress(e) && e.preventDefault();}}
                    />
                  
                 {this.state.searchtextvalue == "" ?
                 null:
                 <InputGroupAddon addonType="prepend">
                 <InputGroupText>
                 <Button onClick={(e)=>this.cancelledsearchbuttonclick()}  className="cancelledbuttonsearch">X</Button>
                                  
                <Button className="buttonsearch" onClick={(e)=>this.searchfunctionstart()}>Search</Button >
                   
                 </InputGroupText>
               </InputGroupAddon>
                 }
                    
                     
                  
                </InputGroup>
             :
             <InputGroup className="col-6 mr-3  ml-lg-auto alignmiddletbutton input-group-alternative">
             <InputGroupAddon addonType="prepend">
                 <InputGroupText>
                
                   <i className="fas fa-search" />
                  
                 </InputGroupText>
               </InputGroupAddon>
             
                 <Input
                   placeholder="Search" 
                   value={this.state.searchtextvalue}
                   onChange = {(event) => this.setState({ searchtextvalue: event.target.value })}
                   type="text"
                   onKeyPress={(e) => {this.handleKeyPress(e) && e.preventDefault();}}
                 />
               
              {this.state.searchtextvalue == "" ?
              null:
              <InputGroupAddon addonType="prepend">
              <InputGroupText>
              <span onClick={(e)=>this.cancelledsearchbuttonclick()}  className="cancelledbuttonsearch">X</span>
                               
             <span className="buttonsearch" onClick={(e)=>this.searchfunctionstart()}>Search</span >
                
              </InputGroupText>
            </InputGroupAddon>
              }
                 
                  
               
             </InputGroup>
             }



                </Col>
              </Row>
             
             
           
         
            
              {this.state.loading
              ?
              <Row >
                <Col md="12" >
              <img 
              className="img-center mt-5"
              width="120"
              height="120"
              alt="..."
              src={require("./../../assets/gif/lorrygif3.gif")}
            />
            </Col>
              </Row>
              :
              <CardBody >
                
                <MapWrapper />
            
              
               
              </CardBody>
                  }

                  
             
                
              </Card>
            </div>
          </Row>
          
        </Container>
     
      </>
    );
  }
}




export default Geofence;
