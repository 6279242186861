/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim
*https://cors-anywhere.herokuapp.com/http://reacttest.winnercloudstudio.com/api/mewahapicode.php?mode=
*http://reacttest.winnercloudstudio.com/api/mewahapicode.php?mode=
=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Report from "views/Report.jsx";
import Location from "views/examples/Location.jsx";
import Login from "views/examples/Login.jsx";
import History from "views/examples/History.jsx";
import HistoryRoute from "views/examples/HistoryRoute.jsx";
import Geofence from "views/examples/Geofence.jsx";
var routes = [
 
 
  {
    path: "/location",
    name: "Location",
    icon: "ni ni-collection text-blue",
    component: Location,
    layout: "/admin"
  },
  {
    path: "/history",
    name: "History",
    icon: "ni ni-money-coins text-blue",
    component: History,
    layout: "/admin"
  },
  {
    
    path: "/geofence",
    name: "Geofence",
    icon: "ni ni-collection text-blue",
    component: Geofence,
    layout: "/admin"
  },
  {
    path: "/report",
    name: "Report",
    icon: "ni ni-tv-2 text-blue",
    component: Report,
    layout: "/admin"
  },
  {
    path: "/historyroute",
    component: HistoryRoute,
    layout: "/admin"
  },
 
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth"
  },
 
 
];
export default routes;
