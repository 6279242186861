/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from 'react';
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar,Doughnut,Pie  } from "react-chartjs-2";
import "assets/vendor/nucleo/css/nucleo.css";
import Moment from 'moment';
// reactstrap components


// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
  chartExample3,
  chartExample4
} from "variables/charts.jsx";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Badge,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Progress,
  Table,
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Button,
  Modal,
  Dropdown,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Alert,
} from "reactstrap";
// core components
import { Link, Route, withRouter } from 'react-router-dom';
import Header from "components/Headers/Header.jsx";
import classnames from "classnames";
import Popup from "reactjs-popup";
import { Autocomplete } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import ReactDatetime from "react-datetime";
import HistoryRoute from "views/examples/HistoryRoute.jsx";

const MyMapComponent = withScriptjs(withGoogleMap((props) =>
<GoogleMap
defaultZoom={10.5}
defaultCenter={{lat:1.5119686, lng:103.8515396}}
>
{props.isMarkerShown && <Marker position={{ lat: -34.397, lng: 150.644 }} />}
</GoogleMap>
))
  
class Report extends React.Component {
  

 
  
  state = {
    ontimekpilistfortable:[],
    loading:false,
    tabs: 1,
    exampleModal: false,
    editpopuout:false,
    isopendropdownarea:false,
    areadropdowntext:'',
    Billtoparty:sessionStorage.getItem('Billtoparty'),
    lasteditby:sessionStorage.getItem('Ref'),
    arealist:[],
    shippervalue:'',
    addressvalue:'',
    picvalue:'',
    depogatechargevalue:'',
    contactvalue:'',
    backtobackchargevalue:'',
    gp20pgu:'',
    isotank20pgu:'',
    rf20pgu:'',
    ot20pgu:'',
    tipper20pgu:'',
    gp40pgu:'',
    rf40pgu:'',
    isotank40pgu:'',
    tipper40pgu:'',
    gp20ptp:'',
    isotank20ptp:'',
    rf20ptp:'',
    ot20ptp:'',
    tipper20ptp:'',
    gp40ptp:'',
    rf40ptp:'',
    isotank40ptp:'',
    tipper40ptp:'',
    aftersubmitstatus:0,
    alerterrormessage:'',
    errorsubmit:false,
    quotationnumbershow:'',
    labelsixmonth:["Jan","Feb","Mar","Apr","May","Jun"],
    //linkurl:"https://cors-anywhere.herokuapp.com/http://reacttest.winnercloudstudio.com/api/mewahapicode.php?",
    linkurl:'https://gpstracking.mewahtrans.com.my/api/alfredtest.php?',
   imageurllink:"https://gpstracking.mewahtrans.com.my/api/img/profile_img/",
  };

  showroute= () =>{
    sessionStorage.setItem("LorryNo",document.getElementById("combo-box-demo2").value)
    let ID=""
    for (var key in this.state.arealist){
      if (this.state.arealist[key].devicename=document.getElementById("combo-box-demo2").value) {
        ID=this.state.arealist[key].DeviceID
      }
    }
    sessionStorage.setItem("ID",ID)
    sessionStorage.setItem("startDate",new Date(this.state.startDate).getTime()/1000)
    sessionStorage.setItem("endDate",new Date(this.state.endDate).getTime()/1000+86399)
    this.props.history.push({
      pathname: '/admin/historyroute',
      state: {
        id: 0,
      }
    })
    return  (
    <>
    </>
    )
  }

  componentDidMount(){
    let link = this.state.linkurl + 'mode=readdevicelist'
    
  //  this.gettotalcontainerbymonthontimekpi()
   
    fetch(link, {
          method: 'POST', 
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
           // 'Access-Control-Allow-Origin': '*',
          },
        })
          .then(response => response.json())
          .then(json => {
            console.log(json.success)
            if(json.success == 1){
              for (let i=0; i<json.user.length; i++){
                this.setState({arealist:[...this.state.arealist,
                  {
                    Ref:json.user[i].Ref,
                    fakeIP:json.user[i].fakeIP,
                    DeviceID:json.user[i].DeviceID,
                    devicename:json.user[i].vehiclename,
                    Isenabled:json.user[i].Isenabled,
                    PlateNumber:json.user[i].PlateNumber,
                    Drivername:json.user[i].Drivername,
                    IC:json.user[i].IC,
                  },
                ]})
              }
  
            }
 
          })
  }
 
  gettotalcontainerbymonthontimekpi = () =>{

    
    var urlencoded = new URLSearchParams();
    var timestampnow = new Date().getTime()/1000;
    //last 6 month
    var timestamp6monthbefore = Moment().subtract(6,"months")
    var datestart = new Date(timestamp6monthbefore).getDate()
    var twodigitdate = ("0" + datestart).slice(-2);
    var monthstart = new Date(timestamp6monthbefore).getMonth() +1
    var twodigitmonth = ("0" + monthstart).slice(-2);
    var yearstart = new Date(timestamp6monthbefore).getFullYear()
    var totaldatestart = yearstart + "-" + twodigitmonth + "-" + "01" + "T00:00:00+00:00"
    var starttimetopass = new Date(totaldatestart).getTime()
    var starttimetopassnotmillilast6month = starttimetopass/1000

    //last 5 month
    var timestamp5monthbefore5 = Moment().subtract(5,"months")
    var datestart5 = new Date(timestamp5monthbefore5).getDate()
    var twodigitdate = ("0" + datestart5).slice(-2);
    var monthstart5 = new Date(timestamp5monthbefore5).getMonth() +1
    var twodigitmonth5 = ("0" + monthstart5).slice(-2);
    var yearstart5 = new Date(timestamp5monthbefore5).getFullYear()
    var totaldatestart5 = yearstart5 + "-" + twodigitmonth5 + "-" + "01" + "T00:00:00+00:00"
    var starttimetopass5 = new Date(totaldatestart5).getTime()
    var starttimetopassnotmillilast5month = starttimetopass5/1000

    //last 4 month
    var timestamp5monthbefore4 = Moment().subtract(4,"months")
    var datestart4 = new Date(timestamp5monthbefore4).getDate()
    var twodigitdate4 = ("0" + datestart4).slice(-2);
    var monthstart4 = new Date(timestamp5monthbefore4).getMonth() +1
    var twodigitmonth4 = ("0" + monthstart4).slice(-2);
    var yearstart4 = new Date(timestamp5monthbefore4).getFullYear()
    var totaldatestart4 = yearstart4 + "-" + twodigitmonth4 + "-" + "01" + "T00:00:00+00:00"
    var starttimetopass4 = new Date(totaldatestart4).getTime()
    var starttimetopassnotmillilast4month = starttimetopass4/1000


    //last 3 month
    var timestamp5monthbefore3 = Moment().subtract(3,"months")
    var datestart3 = new Date(timestamp5monthbefore3).getDate()
    var twodigitdate3 = ("0" + datestart3).slice(-2);
    var monthstart3 = new Date(timestamp5monthbefore3).getMonth() +1
    var twodigitmonth3 = ("0" + monthstart3).slice(-2);
    var yearstart3 = new Date(timestamp5monthbefore3).getFullYear()
    var totaldatestart3 = yearstart3 + "-" + twodigitmonth3 + "-" + "01" + "T00:00:00+00:00"
    var starttimetopass3 = new Date(totaldatestart3).getTime()
    var starttimetopassnotmillilast3month = starttimetopass3/1000


    //last 2 month
    var timestamp5monthbefore2 = Moment().subtract(2,"months")
    var datestart2 = new Date(timestamp5monthbefore2).getDate()
    var twodigitdate2 = ("0" + datestart5).slice(-2);
    var monthstart2 = new Date(timestamp5monthbefore2).getMonth() +1
    var twodigitmonth2 = ("0" + monthstart2).slice(-2);
    var yearstart2 = new Date(timestamp5monthbefore2).getFullYear()
    var totaldatestart2 = yearstart2 + "-" + twodigitmonth2 + "-" + "01" + "T00:00:00+00:00"
    var starttimetopass2 = new Date(totaldatestart2).getTime()
    var starttimetopassnotmillilast2month = starttimetopass2/1000


    //last 1 month
    var timestamp1monthbefore = Moment().subtract(1,"months")
    var datestart1 = new Date(timestamp1monthbefore).getDate()
    var twodigitdate1 = ("0" + datestart1).slice(-2);
    var monthstart1 = new Date(timestamp1monthbefore).getMonth() +1
    var twodigitmonth1 = ("0" + monthstart1).slice(-2);
    var yearstart1 = new Date(timestamp1monthbefore).getFullYear()
    var totaldatestart1 = yearstart1 + "-" + twodigitmonth1 + "-" + "01" + "T00:00:00+00:00"
    var starttimetopass1 = new Date(totaldatestart1).getTime()
    var starttimetopassnotmillilast1month = starttimetopass1/1000

    //last this month head
    var timestamp1monthbefore0 = Moment().subtract(0,"months")
    var datestart0 = new Date(timestamp1monthbefore0).getDate()
    var twodigitdate0 = ("0" + datestart0).slice(-2);
    var monthstart0 = new Date(timestamp1monthbefore0).getMonth() +1
    var twodigitmonth0 = ("0" + monthstart0).slice(-2);
    var yearstart0 = new Date(timestamp1monthbefore0).getFullYear()
    var totaldatestart0 = yearstart0 + "-" + twodigitmonth0 + "-" + "01" + "T00:00:00+00:00"
    var starttimetopass0 = new Date(totaldatestart0).getTime()
    var starttimetopassnotmillilast1month0 = starttimetopass0/1000
    if( monthstart0 == 1){
      this.state.labelsixmonth[5] = "Jan"
      this.state.labelsixmonth[4] = "Dec"
      this.state.labelsixmonth[3] = "Nov"
      this.state.labelsixmonth[2] = "Oct"
      this.state.labelsixmonth[1] = "Sep"
      this.state.labelsixmonth[0] = "Aug"
    }else if( monthstart0 == 2){
      this.state.labelsixmonth[5] = "Feb"
      this.state.labelsixmonth[4] = "Jan"
      this.state.labelsixmonth[3] = "Dec"
      this.state.labelsixmonth[2] = "Nov"
      this.state.labelsixmonth[1] = "Oct"
      this.state.labelsixmonth[0] = "Sep"
    }else if( monthstart0 == 3){
      this.state.labelsixmonth[5] = "Mar"
      this.state.labelsixmonth[4] = "Feb"
      this.state.labelsixmonth[3] = "Jan"
      this.state.labelsixmonth[2] = "Dec"
      this.state.labelsixmonth[1] = "Nov"
      this.state.labelsixmonth[0] = "Oct"
    }else if( monthstart0 == 4){
      this.state.labelsixmonth[5] = "Apr"
      this.state.labelsixmonth[4] = "Mar"
      this.state.labelsixmonth[3] = "Feb"
      this.state.labelsixmonth[2] = "Jan"
      this.state.labelsixmonth[1] = "Dec"
      this.state.labelsixmonth[0] = "Nov"
    }else if( monthstart0 == 5){
      this.state.labelsixmonth[5] = "May"
      this.state.labelsixmonth[4] = "Apr"
      this.state.labelsixmonth[3] = "Mar"
      this.state.labelsixmonth[2] = "Feb"
      this.state.labelsixmonth[1] = "Jan"
      this.state.labelsixmonth[0] = "Dec"
    }else if( monthstart0 == 6){
      this.state.labelsixmonth[5] = "Jun"
      this.state.labelsixmonth[4] = "May"
      this.state.labelsixmonth[3] = "Apr"
      this.state.labelsixmonth[2] = "Mar"
      this.state.labelsixmonth[1] = "Feb"
      this.state.labelsixmonth[0] = "Jan"
    }else if( monthstart0 == 7){
      this.state.labelsixmonth[5] = "Jul"
      this.state.labelsixmonth[4] = "Jun"
      this.state.labelsixmonth[3] = "May"
      this.state.labelsixmonth[2] = "Apr"
      this.state.labelsixmonth[1] = "Mar"
      this.state.labelsixmonth[0] = "Feb"
    }else if( monthstart0 == 8){
      this.state.labelsixmonth[5] = "Aug"
      this.state.labelsixmonth[4] = "Jul"
      this.state.labelsixmonth[3] = "Jun"
      this.state.labelsixmonth[2] = "May"
      this.state.labelsixmonth[1] = "Apr"
      this.state.labelsixmonth[0] = "Mar"
    }else if( monthstart0 == 9){
      this.state.labelsixmonth[5] = "Sep"
      this.state.labelsixmonth[4] = "Aug"
      this.state.labelsixmonth[3] = "Jul"
      this.state.labelsixmonth[2] = "Jun"
      this.state.labelsixmonth[1] = "May"
      this.state.labelsixmonth[0] = "Apr"
    }else if( monthstart0 == 10){
      this.state.labelsixmonth[5] = "Oct"
      this.state.labelsixmonth[4] = "Sep"
      this.state.labelsixmonth[3] = "Aug"
      this.state.labelsixmonth[2] = "Jul"
      this.state.labelsixmonth[1] = "Jun"
      this.state.labelsixmonth[0] = "May"
    }else if( monthstart0 == 11){
      this.state.labelsixmonth[5] = "Nov"
      this.state.labelsixmonth[4] = "Oct"
      this.state.labelsixmonth[3] = "Sep"
      this.state.labelsixmonth[2] = "Aug"
      this.state.labelsixmonth[1] = "Jul"
      this.state.labelsixmonth[0] = "Jun"
    }else if( monthstart0 == 12){
      this.state.labelsixmonth[5] = "Dec"
      this.state.labelsixmonth[4] = "Nov"
      this.state.labelsixmonth[3] = "Oct"
      this.state.labelsixmonth[2] = "Sep"
      this.state.labelsixmonth[1] = "Aug"
      this.state.labelsixmonth[0] = "Jul"
    }
    


     //next month head
     var timestamp1monthbeforeadd1 = Moment().add(1,"months")
     var datestartadd1 = new Date(timestamp1monthbeforeadd1).getDate()
     var twodigitdateadd1 = ("0" + datestartadd1).slice(-2);
     var monthstartadd1 = new Date(timestamp1monthbeforeadd1).getMonth() +1
     var twodigitmonthadd1 = ("0" + monthstartadd1).slice(-2);
     var yearstartadd1 = new Date(timestamp1monthbeforeadd1).getFullYear()
     var totaldatestartadd1 = yearstartadd1 + "-" + twodigitmonthadd1 + "-" + "01" + "T00:00:00+00:00"
     var starttimetopassadd1 = new Date(totaldatestartadd1).getTime()
     var starttimetopassnotmillilast1monthadd1 = starttimetopassadd1/1000
   

    console.log("starttimetopassnotmillilast1month0: " + starttimetopassnotmillilast1month0)
    console.log("starttimetopassnotmillilast1month: " + starttimetopassnotmillilast1month)
    console.log("starttimetopassnotmillilast2month: " + starttimetopassnotmillilast2month)
    console.log("starttimetopassnotmillilast3month: " + starttimetopassnotmillilast3month)
    console.log("starttimetopassnotmillilast4month: " + starttimetopassnotmillilast4month)
    console.log("starttimetopassnotmillilast5month: " + starttimetopassnotmillilast5month)
    console.log("starttimetopassnotmillilast6month: " + starttimetopassnotmillilast6month)
    console.log("starttimetopassnotmillilast1monthadd1: " + starttimetopassnotmillilast1monthadd1)


    urlencoded.append("billtoparty",this.state.Billtoparty);
    urlencoded.append("starttimetopassnotmilli",starttimetopassnotmillilast6month);
    urlencoded.append("starttimetopassnotmillilast1monthadd1",starttimetopassnotmillilast1monthadd1);
    let link = this.state.linkurl +'mode=admin-gettotalcontainerbymonth'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
       
      if(json.success == 1){
        this.setState({failontime:0})
        this.setState({successontime:0})
        var lengthemployee = Object.keys(json.user).length;
        for(let i=0;i<lengthemployee;i++){        
         
          /////Total Container By Month, IMPORT EXPORT-----------------------------------------------------------------------------------------
          //LAST 5 MONTH
          if(json.user[i].Activitydatetruckin-28800 > starttimetopassnotmillilast5month && json.user[i].Activitydatetruckin-28800 <= starttimetopassnotmillilast4month){
           // console.log("sixmonthtotalcontainersize: " +  this.state.sixmonthtotalcontainersize)
            if(json.user[i].importexport == "Export"){
              this.state.sixmonthtotalexport[0] = this.state.sixmonthtotalexport[0] + 1
            }else if(json.user[i].importexport == "Import"){
              this.state.sixmonthtotalimport[0] = this.state.sixmonthtotalimport[0] + 1
            }
            this.state.sixmonthtotalcontainersize[0] = this.state.sixmonthtotalcontainersize[0] + 1
          }

          //LAST 4 MONTH
          if(json.user[i].Activitydatetruckin-28800 > starttimetopassnotmillilast4month && json.user[i].Activitydatetruckin-28800 <= starttimetopassnotmillilast3month){
          //  console.log("sixmonthtotalcontainersize: " +  this.state.sixmonthtotalcontainersize)
            if(json.user[i].importexport == "Export"){
              this.state.sixmonthtotalexport[1] = this.state.sixmonthtotalexport[1] + 1
            }else if(json.user[i].importexport == "Import"){
              this.state.sixmonthtotalimport[1] = this.state.sixmonthtotalimport[1] + 1
            }
            this.state.sixmonthtotalcontainersize[1] = this.state.sixmonthtotalcontainersize[1] + 1
          }

           //LAST 3 MONTH
           if(json.user[i].Activitydatetruckin-28800 > starttimetopassnotmillilast3month && json.user[i].Activitydatetruckin-28800 <= starttimetopassnotmillilast2month){
           // console.log("sixmonthtotalcontainersize: " +  this.state.sixmonthtotalcontainersize)
            if(json.user[i].importexport == "Export"){
              this.state.sixmonthtotalexport[2] = this.state.sixmonthtotalexport[2] + 1
            }else if(json.user[i].importexport == "Import"){
              this.state.sixmonthtotalimport[2] = this.state.sixmonthtotalimport[2] + 1
            } 
           this.state.sixmonthtotalcontainersize[2] = this.state.sixmonthtotalcontainersize[2] + 1
          }

             //LAST 2 MONTH
             if(json.user[i].Activitydatetruckin-28800 > starttimetopassnotmillilast2month && json.user[i].Activitydatetruckin-28800 <= starttimetopassnotmillilast1month){
             // console.log("sixmonthtotalcontainersize: " +  this.state.sixmonthtotalcontainersize)
              if(json.user[i].importexport == "Export"){
                this.state.sixmonthtotalexport[3] = this.state.sixmonthtotalexport[3] + 1
              }else if(json.user[i].importexport == "Import"){
                this.state.sixmonthtotalimport[3] = this.state.sixmonthtotalimport[3] + 1
              }  
             this.state.sixmonthtotalcontainersize[3] = this.state.sixmonthtotalcontainersize[3] + 1
            }

            //LAST 1 MONTH
            if(json.user[i].Activitydatetruckin-28800 > starttimetopassnotmillilast1month && json.user[i].Activitydatetruckin-28800 <= starttimetopassnotmillilast1month0){
             // console.log("sixmonthtotalcontainersize: " +  this.state.sixmonthtotalcontainersize)
              if(json.user[i].importexport == "Export"){
                this.state.sixmonthtotalexport[4] = this.state.sixmonthtotalexport[4] + 1
              }else if(json.user[i].importexport == "Import"){
                this.state.sixmonthtotalimport[4] = this.state.sixmonthtotalimport[4] + 1
              }   
             this.state.sixmonthtotalcontainersize[4] = this.state.sixmonthtotalcontainersize[4] + 1
            }
          
            //this MONTH
            if(json.user[i].Activitydatetruckin-28800 > starttimetopassnotmillilast1month0 ){
                //console.log("thismonth:" + Moment.unix(json.user[i].Activitydatetruckin).format("DD/MM/YYYY"))
                //console.log("sixmonthtotalcontainersize: " +  this.state.sixmonthtotalcontainersize)
                if(json.user[i].importexport == "Export"){
                  this.state.sixmonthtotalexport[5] = this.state.sixmonthtotalexport[5] + 1
                }else if(json.user[i].importexport == "Import"){
                  this.state.sixmonthtotalimport[5] = this.state.sixmonthtotalimport[5] + 1
                }   
                this.state.sixmonthtotalcontainersize[5] = this.state.sixmonthtotalcontainersize[5] + 1
            }


            /////top Shipper vs container---------------------------------------------------------------------------
            var lengthoftopshipperlist = Object.keys(this.state.topshipperlist).length;
            for(let j = 0;j< lengthoftopshipperlist ; j ++){
              if(json.user[i].Shipper == this.state.topshipperlist[j].shippername){
                this.state.topshipperlist[j].containercount = this.state.topshipperlist[j].containercount + 1 
                //console.log("topshipplerlistcount: " + this.state.topshipperlist[j].containercount)
            }
          }
         
             /////on time kpi-----------------------------------------------------------
             if(json.user[i].Requestdate-28800 == 946684800-28800 || json.user[i].Activitydatetruckin-28800 == 946684800-28800 ){
             }else{
              if(json.user[i].Requestdate-28800 > json.user[i].Activitydatetruckin-28800 || Moment.unix(json.user[i].Requestdate-28800).format("DD/MM/YYYY") == Moment.unix(json.user[i].Activitydatetruckin-28800).format("DD/MM/YYYY")){
                this.state.successontime = this.state.successontime + 1
              }else{
                this.state.failontime = this.state.failontime + 1
                this.setState({ontimekpilistfortable:[...this.state.ontimekpilistfortable,
                  { no:this.state.failontime,
                    consignmentno:json.user[i].Consignmentno,
                    shipper:json.user[i].Shipper,
                    bookingno:json.user[i].Bookingno,
                    containerno:json.user[i].Containerno,
                    requestdate:Moment.unix(json.user[i].Requestdate-28800).format("DD/MM/YYYY"),
                    assigndate:Moment.unix(json.user[i].Activitydatetruckin-28800).format("DD/MM/YYYY"),
                  },            
                ]})
              }
             }
            

             

        }

          this.state.topshipperlist.sort((a,b) => (a.containercount < b.containercount) ?1:-1)
          //console.log("shipperlistsort: " + this.state.topshipperlist)
          // for(let j = 0;j< lengthoftopshipperlist ; j ++){
          //   if(this.state.topshipperlist[j].containercount != 0){
          //   console.log("topshippernamesort: " + this.state.topshipperlist[j].shippername)
          //   console.log("topshipplerlistcountsort: " + this.state.topshipperlist[j].containercount)
          //   }
          // }
        this.setState({
          datacontainertotalbymonth: {
            labels:this.state.labelsixmonth,
            datasets: [
              {
                label: "Container",
                data: this.state.sixmonthtotalcontainersize,
              }
            ]
          },
        })

        this.setState({
          datacontainertotalbymonthimportexport: {
            labels: this.state.labelsixmonth,
            datasets: [{
              label: 'Import ',
              yAxisID: 'Import ',
              data: this.state.sixmonthtotalimport,
              backgroundColor: '#77b5fe',
            }, {
              label: 'Export ',
              yAxisID: 'Export ',
              backgroundColor: '#000080',
              data: this.state.sixmonthtotalexport,
            }]
          },
        })
             
        if(this.state.topshipperlist[0].shippername == null){
          this.setState({
            datatopshipperlist: {
              labels: [0,0,0,0,0,0],
              datasets: [
                {
                  backgroundColor: 'rgb(212,175,55)',
                  label: "Shipper",
                  data: [this.state.topshipperlist[0].containercount, this.state.topshipperlist[1].containercount, this.state.topshipperlist[2].containercount, this.state.topshipperlist[3].containercount, this.state.topshipperlist[4].containercount, this.state.topshipperlist[5].containercount]
                }
              ]
            },
          })
        }else{
          this.setState({
            datatopshipperlist: {
              labels: [this.state.topshipperlist[0].shippername.slice(0,8),this.state.topshipperlist[1].shippername.slice(0,8),this.state.topshipperlist[2].shippername.slice(0,8),this.state.topshipperlist[3].shippername.slice(0,8),this.state.topshipperlist[4].shippername.slice(0,8),this.state.topshipperlist[5].shippername.slice(0,8)],
              datasets: [
                {
                  backgroundColor: 'rgb(212,175,55)',
                  label: "Shipper",
                  data: [this.state.topshipperlist[0].containercount, this.state.topshipperlist[1].containercount, this.state.topshipperlist[2].containercount, this.state.topshipperlist[3].containercount, this.state.topshipperlist[4].containercount, this.state.topshipperlist[5].containercount]
                }
              ]
            },
          })
        }
          

            this.setState({
              datalistontimekpi:{    
                labels: ["Fail", "OnTime"],
                datasets: [
                  {
                    backgroundColor: ['rgb(0, 255, 255)','rgb(0, 128, 128)'],
                  
                    label: "KPI",
                    data: [this.state.failontime, this.state.successontime]
                  }
                ]    
            },
            })



      }
      else{
          //this.setState({loading:false}) 
          

          this.setState({loadingdashboard:false})
        console.log(json)
      }
    
    })
  
  }
 
  renderontimekpimodallist() {
      
    if(this.state.currentquotationpagenumber ==1){
      return this.state.ontimekpilistfortable.slice(0,50).map((data, index) => {
        const {containerno,consignmentno,no,shipper,bookingno,requestdate,assigndate,} = data //destructuring
        var rotlength = Object.keys(this.state.ontimekpilistfortable).length;
        return (
         <tbody>
         <tr>
           <th scope="row">{consignmentno}</th>
           <td>{shipper}</td>
           <td>{bookingno}</td>
           <td>{containerno}</td>
           <td>{requestdate}</td>
           <td>{assigndate}</td>
           <td> <Badge color="" className="badge-dot mr-4">
           
           <i className="bg-warning" />                        
           
           Fail
           </Badge></td>
         </tr>  
       </tbody>
        )
     })         
    }else if(this.state.currentquotationpagenumber ==2){
      return this.state.ontimekpilistfortable.slice(50,100).map((data, index) => {
        const {containerno,consignmentno,no,shipper,bookingno,requestdate,assigndate,} = data //destructuring
        var rotlength = Object.keys(this.state.ontimekpilistfortable).length;
        return (
         <tbody>
         <tr>
           <th scope="row">{consignmentno}</th>
           <td>{shipper}</td>
           <td>{bookingno}</td>
           <td>{containerno}</td>
           <td>{requestdate}</td>
           <td>{assigndate}</td>
           <td> <Badge color="" className="badge-dot mr-4">
           
           <i className="bg-warning" />                        
           
           Fail
           </Badge></td>
         </tr>  
       </tbody>
        )
     })     
    }else if(this.state.currentquotationpagenumber ==3){
      return this.state.ontimekpilistfortable.slice(100,150).map((data, index) => {
        const {containerno,consignmentno,no,shipper,bookingno,requestdate,assigndate,} = data //destructuring
        var rotlength = Object.keys(this.state.ontimekpilistfortable).length;
        return (
         <tbody>
         <tr>
           <th scope="row">{consignmentno}</th>
           <td>{shipper}</td>
           <td>{bookingno}</td>
           <td>{containerno}</td>
           <td>{requestdate}</td>
           <td>{assigndate}</td>
           <td> <Badge color="" className="badge-dot mr-4">
           
           <i className="bg-warning" />                        
           
           Fail
           </Badge></td>
         </tr>  
       </tbody>
        )
     })     
    }else if(this.state.currentquotationpagenumber ==4){
      return this.state.ontimekpilistfortable.slice(150,200).map((data, index) => {
        const {containerno,consignmentno,no,shipper,bookingno,requestdate,assigndate,} = data //destructuring
        var rotlength = Object.keys(this.state.ontimekpilistfortable).length;
        return (
         <tbody>
         <tr>
           <th scope="row">{consignmentno}</th>
           <td>{shipper}</td>
           <td>{bookingno}</td>
           <td>{containerno}</td>
           <td>{requestdate}</td>
           <td>{assigndate}</td>
           <td> <Badge color="" className="badge-dot mr-4">
           
           <i className="bg-warning" />                        
           
           Fail
           </Badge></td>
         </tr>  
       </tbody>
        )
     })     
    }else if(this.state.currentquotationpagenumber ==5){
      return this.state.ontimekpilistfortable.slice(250,300).map((data, index) => {
        const {containerno,consignmentno,no,shipper,bookingno,requestdate,assigndate,} = data //destructuring
        var rotlength = Object.keys(this.state.ontimekpilistfortable).length;
        return (
         <tbody>
         <tr>
           <th scope="row">{consignmentno}</th>
           <td>{shipper}</td>
           <td>{bookingno}</td>
           <td>{containerno}</td>
           <td>{requestdate}</td>
           <td>{assigndate}</td>
           <td> <Badge color="" className="badge-dot mr-4">
           
           <i className="bg-warning" />                        
           
           Fail
           </Badge></td>
         </tr>  
       </tbody>
        )
     })     
    }else if(this.state.currentquotationpagenumber >= 6){
      return this.state.ontimekpilistfortable.slice(300,1000).map((data, index) => {
        const {containerno,consignmentno,no,shipper,bookingno,requestdate,assigndate,} = data //destructuring
        var rotlength = Object.keys(this.state.ontimekpilistfortable).length;
        return (
         <tbody>
         <tr>
           <th scope="row">{consignmentno}</th>
           <td>{shipper}</td>
           <td>{bookingno}</td>
           <td>{containerno}</td>
           <td>{requestdate}</td>
           <td>{assigndate}</td>
           <td> <Badge color="" className="badge-dot mr-4">
           
           <i className="bg-warning" />                        
           
           Fail
           </Badge></td>
         </tr>  
       </tbody>
        )
     })     
    }
   
  }



  render() {
    


    
    return (
      <>
        <Header />
       
        {/* Page content */}
        <Container className=" mt--7" fluid>
          {/* Table */}
          <Row>

            <div className=" col">
              <Card className=" shadow">
              <CardHeader className="border-10-blue">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Report Check:</h3>
                    </div>
                    
                  </Row>
                </CardHeader>
              
              {
              /* Success Status*/
              this.state.aftersubmitstatus == 1
              ?             
              <CardBody>
              <div className="modal-header">
          </div>
          <div className="modalforquotationaddnew">
          <Form>
            <Row>
            <Col md="12">
                <img 
                className="img-quotationlogodonenew"
                width="120"
                height="120"
                alt="..."
                src={require("./../assets/img/brand/logomewah4.png")}
              />
              <p className="word-quotationaddnewfinish" >Thanks for choose our services,</p>
              <p className="word-quotationaddnewfinish">Your Quotation Number is {this.state.quotationnumbershow}.</p>
             
               <Button
              color="primary"
              data-dismiss="modal"
              type="button"
              to="/admin/quotation"
              tag={Link}
              >
              Go To Pending Approval
            </Button>
            
            <Button
              color="primary"
              data-dismiss="modal"
              type="button"
              onClick={() => this.buttonclickedtocreatenewquotation()}
              >
              Create Another Quotation
            </Button>
            </Col>
            
          </Row>
          </Form>
         </div>
           
          </CardBody>
              :
              /* Fail Status */
              this.state.aftersubmitstatus == 2
              ?
              <CardBody>
              <div className="modal-header">
          </div>
          <div className="modalforquotationaddnew">
          <Form>
            <Row>
            <Col md="12">
              <p className="h2">Submit Failed, Something Wrong, Please Try Again Later.</p>
              <Button
              color="primary"
              data-dismiss="modal"
              type="button"
              className="buttonmiddle"
              onClick={() => this.buttonclickedtocreatenewquotation()}
              >
              Retry
            </Button>
            </Col>
            
          </Row>
          </Form>
         </div>
           
          </CardBody>
              :
              /* Loading Status */
              this.state.aftersubmitstatus == 3
              ?
              <Row >
                <Col md="12" >
                  <img 
                  className="img-center"
                  width="120"
                  height="120"
                  alt="..."
                  src={require("./../assets/gif/lorrygif3.gif")}
                />
                </Col>
            </Row>
              :
              /* Normal Status */
             <CardBody>
      <div className="modal-header">
   
  </div>
  <div className="modal-body">
  <Form>
  {this.state.errorsubmit == true ?
                     <Alert color="danger">
                     {this.state.alerterrormessage}
                   </Alert>
                   :
                   null
                   }
   
   {/*Lorry NUmber */}
   <Row>
  <Col md="12">
      <p className="h5">Report Type:</p>
      {(e)=>this.readdevicelist()}
      <FormGroup>
      <Autocomplete
                        id="combo-box-demo2"
                        options={[{devicename:'Fuel Report'},{devicename:'Speed Exceed Report'},{devicename:'Most Driving Report'}]}
                        getOptionLabel={(option) => option.devicename}                       
                        style={{ flex:1,display:"block" }}
                        onInputChange={(event,value) =>this.setState({areadropdowntext:value})}
                        renderInput={(params) => <TextField {...params} label="" variant="outlined"  />}
                        disableClearable={true}
                      />

     
      </FormGroup>
      </Col>     
   
  </Row>
  

    {/*Lorry NUmber */}
    <Row>
  <Col md="12">
      <p className="h5">Lorry Number:</p>
      {(e)=>this.readdevicelist()}
      <FormGroup>
      <Autocomplete
                        id="combo-box-demo2"
                        options={this.state.arealist}
                        getOptionLabel={(option) => option.devicename}                       
                        style={{ flex:1,display:"block" }}
                        onInputChange={(event,value) =>this.setState({areadropdowntext:value})}
                        renderInput={(params) => <TextField {...params} label="" variant="outlined"  />}
                        disableClearable={true}
                      />

     
      </FormGroup>
      </Col>     
   
  </Row>


    {/*Date Time */}
  <Row>
  <Col md={6}>
  <p className="h5">Date Time:</p>
            <FormGroup>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-calendar-grid-58" />
                  </InputGroupText>
                </InputGroupAddon>
                <ReactDatetime
                  inputProps={{
                    placeholder: "From Date"
                  }}
                  dateFormat="DD/MM/YYYY"
                    timeFormat={false}
                  renderDay={(props, currentDate, selectedDate) => {
                    let classes = props.className;
                    if (
                      this.state.startDate &&
                      this.state.endDate &&
                      this.state.startDate._d + "" === currentDate._d + ""
                    ) {
                      classes += " start-date";
                    } else if (
                      this.state.startDate &&
                      this.state.endDate &&
                      new Date(this.state.startDate._d + "") <
                        new Date(currentDate._d + "") &&
                      new Date(this.state.endDate._d + "") >
                        new Date(currentDate._d + "")
                    ) {
                      classes += " middle-date";
                    } else if (
                      this.state.endDate &&
                      this.state.endDate._d + "" === currentDate._d + ""
                    ) {
                      classes += " end-date";
                    }
                    return (
                      <td {...props} className={classes}>
                        {currentDate.date()}
                      </td>
                    );
                  }}
                  
                  closeOnSelect={true}
                  onChange={e =>this.setState({ startDate: e })  }
                  value={this.state.startDate}
                />
              </InputGroup>
            </FormGroup>
          </Col>
                   


                    <Col md={6}>
                    <p className="h5">_</p>
            <FormGroup>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-calendar-grid-58" />
                  </InputGroupText>
                </InputGroupAddon>
                <ReactDatetime
                  inputProps={{
                    placeholder: "To Date"
                  }}
                  dateFormat="DD/MM/YYYY"
                    timeFormat={false}
                  renderDay={(props, currentDate, selectedDate) => {
                    let classes = props.className;
                    if (
                      this.state.startDate &&
                      this.state.endDate &&
                      this.state.startDate._d + "" === currentDate._d + ""
                    ) {
                      classes += " start-date";
                    } else if (
                      this.state.startDate &&
                      this.state.endDate &&
                      new Date(this.state.startDate._d + "") <
                        new Date(currentDate._d + "") &&
                      new Date(this.state.endDate._d + "") >
                        new Date(currentDate._d + "")
                    ) {
                      classes += " middle-date";
                    } else if (
                      this.state.endDate &&
                      this.state.endDate._d + "" === currentDate._d + ""
                    ) {
                      classes += " end-date";
                    }
                    return (
                      <td {...props} className={classes}>
                        {currentDate.date()}
                      </td>
                    );
                  }}
                  
                  closeOnSelect={true}
                  onChange={e =>this.setState({ endDate: e })}
                  value={this.state.endDate}
                />
                {/* , this.setState({ endDate: e }) */}
              </InputGroup>
            </FormGroup>
          </Col>
                   
   
  </Row>

</Form>

<Button color="primary" type="button" onClick={this.showroute}>
      Search
    </Button>

    <Row className="mt-5">
        
          {/* On Real Time KPI */}
          <Col xl="12">
              <Card className="shadow">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                     
                      <h2 className="mb-0">On Time KPI</h2>
                    </div>
                    <Button
                      color="secondary"
                      data-dismiss="modal"
                      type="button"
                      className="alignrightbutton"
                      onClick={(e) => this.setState({detailpopout:true})}
                    >
                      See Detail
                    </Button> 
                  </Row>
                </CardHeader>
                <CardBody>
                  {/* Chart */}
                  <div className="chart" >
                    <Pie
                      data={this.state.datalistontimekpi}
                      options={chartExample4.options}                   
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
  </div>
  <div className="modal-footer">
   
  </div>
   
  </CardBody>
              }
              <Row>
              <Col md="12" >
              <p className="h2" >
                

                
              </p>

                </Col>
              </Row>
              <Row>
              <Col md="12" >
                </Col>
              </Row>
              <Row>
              <Col md="12" >
                </Col>
              </Row>
              
              </Card>
            </div>

          </Row>


        </Container>
     
       

      
      </>
    );
  }
}

export default Report;
