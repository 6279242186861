/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from 'react';
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Badge,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Progress,
  Table,
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Button,
  Modal,
  Dropdown,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Alert,
} from "reactstrap";
// core components
import { Link, Route, withRouter } from 'react-router-dom';
import Header from "components/Headers/Header.jsx";
import classnames from "classnames";
import Popup from "reactjs-popup";
import { Autocomplete } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import ReactDatetime from "react-datetime";
import HistoryRoute from "views/examples/HistoryRoute.jsx";

const MyMapComponent = withScriptjs(withGoogleMap((props) =>
<GoogleMap
defaultZoom={10.5}
defaultCenter={{lat:1.5119686, lng:103.8515396}}
>
{props.isMarkerShown && <Marker position={{ lat: -34.397, lng: 150.644 }} />}
</GoogleMap>
))
  
class History extends React.Component {
  

 
  
  state = {
    loading:false,
    tabs: 1,
    exampleModal: false,
    editpopuout:false,
    isopendropdownarea:false,
    areadropdowntext:'',
    Billtoparty:sessionStorage.getItem('Billtoparty'),
    lasteditby:sessionStorage.getItem('Ref'),
    arealist:[],
    shippervalue:'',
    addressvalue:'',
    picvalue:'',
    depogatechargevalue:'',
    contactvalue:'',
    backtobackchargevalue:'',
    gp20pgu:'',
    isotank20pgu:'',
    rf20pgu:'',
    ot20pgu:'',
    tipper20pgu:'',
    gp40pgu:'',
    rf40pgu:'',
    isotank40pgu:'',
    tipper40pgu:'',
    gp20ptp:'',
    isotank20ptp:'',
    rf20ptp:'',
    ot20ptp:'',
    tipper20ptp:'',
    gp40ptp:'',
    rf40ptp:'',
    isotank40ptp:'',
    tipper40ptp:'',
    aftersubmitstatus:0,
    alerterrormessage:'',
    errorsubmit:false,
    quotationnumbershow:'',
    //linkurl:"https://cors-anywhere.herokuapp.com/http://reacttest.winnercloudstudio.com/api/mewahapicode.php?",
    linkurl:'https://gpstracking.mewahtrans.com.my/api/alfredtest.php?',
   imageurllink:"https://gpstracking.mewahtrans.com.my/api/img/profile_img/",
  };

  showroute= () =>{
    sessionStorage.setItem("LorryNo",document.getElementById("combo-box-demo2").value)
    let ID=""
    for (var key in this.state.arealist){
      if (this.state.arealist[key].devicename=document.getElementById("combo-box-demo2").value) {
        ID=this.state.arealist[key].DeviceID
      }
    }
    sessionStorage.setItem("ID",ID)
    sessionStorage.setItem("startDate",new Date(this.state.startDate).getTime()/1000)
    sessionStorage.setItem("endDate",new Date(this.state.endDate).getTime()/1000+86399)
    this.props.history.push({
      pathname: '/admin/historyroute',
      state: {
        id: 0,
      }
    })
    return  (
    <>
    </>
    )
  }

  componentDidMount(){
    let link = this.state.linkurl + 'mode=readdevicelist'
    
   
    fetch(link, {
          method: 'POST', 
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
           // 'Access-Control-Allow-Origin': '*',
          },
        })
          .then(response => response.json())
          .then(json => {
            console.log(json.success)
            if(json.success == 1){
              for (let i=0; i<json.user.length; i++){
                this.setState({arealist:[...this.state.arealist,
                  {
                    Ref:json.user[i].Ref,
                    fakeIP:json.user[i].fakeIP,
                    DeviceID:json.user[i].DeviceID,
                    devicename:json.user[i].vehiclename,
                    Isenabled:json.user[i].Isenabled,
                    PlateNumber:json.user[i].PlateNumber,
                    Drivername:json.user[i].Drivername,
                    IC:json.user[i].IC,
                  },
                ]})
              }
  
            }
 
          })
  }
 
 




  render() {
    


    
    return (
      <>
        <Header />
       
        {/* Page content */}
        <Container className=" mt--7" fluid>
          {/* Table */}
          <Row>

            <div className=" col">
              <Card className=" shadow">
              <CardHeader className="border-10-blue">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">History Route Check:</h3>
                    </div>
                    
                  </Row>
                </CardHeader>
              
              {
              /* Success Status*/
              this.state.aftersubmitstatus == 1
              ?             
              <CardBody>
              <div className="modal-header">
          </div>
          <div className="modalforquotationaddnew">
          <Form>
            <Row>
            <Col md="12">
                <img 
                className="img-quotationlogodonenew"
                width="120"
                height="120"
                alt="..."
                src={require("./../../assets/img/brand/logomewah4.png")}
              />
              <p className="word-quotationaddnewfinish" >Thanks for choose our services,</p>
              <p className="word-quotationaddnewfinish">Your Quotation Number is {this.state.quotationnumbershow}.</p>
             
               <Button
              color="primary"
              data-dismiss="modal"
              type="button"
              to="/admin/quotation"
              tag={Link}
              >
              Go To Pending Approval
            </Button>
            
            <Button
              color="primary"
              data-dismiss="modal"
              type="button"
              onClick={() => this.buttonclickedtocreatenewquotation()}
              >
              Create Another Quotation
            </Button>
            </Col>
            
          </Row>
          </Form>
         </div>
           
          </CardBody>
              :
              /* Fail Status */
              this.state.aftersubmitstatus == 2
              ?
              <CardBody>
              <div className="modal-header">
          </div>
          <div className="modalforquotationaddnew">
          <Form>
            <Row>
            <Col md="12">
              <p className="h2">Submit Failed, Something Wrong, Please Try Again Later.</p>
              <Button
              color="primary"
              data-dismiss="modal"
              type="button"
              className="buttonmiddle"
              onClick={() => this.buttonclickedtocreatenewquotation()}
              >
              Retry
            </Button>
            </Col>
            
          </Row>
          </Form>
         </div>
           
          </CardBody>
              :
              /* Loading Status */
              this.state.aftersubmitstatus == 3
              ?
              <Row >
                <Col md="12" >
                  <img 
                  className="img-center"
                  width="120"
                  height="120"
                  alt="..."
                  src={require("./../../assets/gif/lorrygif3.gif")}
                />
                </Col>
            </Row>
              :
              /* Normal Status */
             <CardBody>
      <div className="modal-header">
   
  </div>
  <div className="modal-body">
  <Form>
  {this.state.errorsubmit == true ?
                     <Alert color="danger">
                     {this.state.alerterrormessage}
                   </Alert>
                   :
                   null
                   }
   
   {/*Lorry NUmber */}
   <Row>
  <Col md="12">
      <p className="h5">Lorry Number:</p>
      {(e)=>this.readdevicelist()}
      <FormGroup>
      <Autocomplete
                        id="combo-box-demo2"
                        options={this.state.arealist}
                        getOptionLabel={(option) => option.devicename}                       
                        style={{ flex:1,display:"block" }}
                        onInputChange={(event,value) =>this.setState({areadropdowntext:value})}
                        renderInput={(params) => <TextField {...params} label="" variant="outlined"  />}
                        disableClearable={true}
                      />

     
      </FormGroup>
      </Col>     
   
  </Row>
  
    {/*Date Time */}
  <Row>
  <Col md={6}>
  <p className="h5">Date Time:</p>
            <FormGroup>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-calendar-grid-58" />
                  </InputGroupText>
                </InputGroupAddon>
                <ReactDatetime
                  inputProps={{
                    placeholder: "From"
                  }}
                  dateFormat="DD/MM/YYYY"
                    timeFormat={true}
                  renderDay={(props, currentDate, selectedDate) => {
                    let classes = props.className;
                    if (
                      this.state.startDate &&
                      this.state.endDate &&
                      this.state.startDate._d + "" === currentDate._d + ""
                    ) {
                      classes += " start-date";
                    } else if (
                      this.state.startDate &&
                      this.state.endDate &&
                      new Date(this.state.startDate._d + "") <
                        new Date(currentDate._d + "") &&
                      new Date(this.state.endDate._d + "") >
                        new Date(currentDate._d + "")
                    ) {
                      classes += " middle-date";
                    } else if (
                      this.state.endDate &&
                      this.state.endDate._d + "" === currentDate._d + ""
                    ) {
                      classes += " end-date";
                    }
                    return (
                      <td {...props} className={classes}>
                        {currentDate.date()}
                      </td>
                    );
                  }}
                  
                  closeOnSelect={true}
                  onChange={e =>this.setState({ startDate: e })  }
                  value={this.state.startDate}
                />
              </InputGroup>
            </FormGroup>
          </Col>
                   


                    <Col md={6}>
                    <p className="h5">_</p>
            <FormGroup>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-calendar-grid-58" />
                  </InputGroupText>
                </InputGroupAddon>
                <ReactDatetime
                  inputProps={{
                    placeholder: "To"
                  }}
                  dateFormat="DD/MM/YYYY"
                    timeFormat={true}
                  renderDay={(props, currentDate, selectedDate) => {
                    let classes = props.className;
                    if (
                      this.state.startDate &&
                      this.state.endDate &&
                      this.state.startDate._d + "" === currentDate._d + ""
                    ) {
                      classes += " start-date";
                    } else if (
                      this.state.startDate &&
                      this.state.endDate &&
                      new Date(this.state.startDate._d + "") <
                        new Date(currentDate._d + "") &&
                      new Date(this.state.endDate._d + "") >
                        new Date(currentDate._d + "")
                    ) {
                      classes += " middle-date";
                    } else if (
                      this.state.endDate &&
                      this.state.endDate._d + "" === currentDate._d + ""
                    ) {
                      classes += " end-date";
                    }
                    return (
                      <td {...props} className={classes}>
                        {currentDate.date()}
                      </td>
                    );
                  }}
                  
                  closeOnSelect={true}
                  onChange={e =>this.setState({ endDate: e })}
                  value={this.state.endDate}
                />
                {/* , this.setState({ endDate: e }) */}
              </InputGroup>
            </FormGroup>
          </Col>
                   
   
  </Row>

</Form>

<Button color="primary" type="button" onClick={this.showroute}>
      Search
    </Button>
  </div>
  <div className="modal-footer">
   
  </div>
   
  </CardBody>
              }
              <Row>
              <Col md="12" >
              <p className="h2" >
                

                
              </p>

                </Col>
              </Row>
              <Row>
              <Col md="12" >
                </Col>
              </Row>
              <Row>
              <Col md="12" >
                </Col>
              </Row>
              
              </Card>
            </div>

          </Row>


        </Container>
     
       

      
      </>
    );
  }
}

export default History;
